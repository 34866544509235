import { Ref, ref } from "vue";
import { FromString, FromNum } from "cerceis-lib";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
// Types
export interface CustomFileObject {
    label: string;
    uid: string;
    type: string;
}

// Generals
export const mobileMode: Ref<boolean> = ref(false);
export const clientWidth: Ref<number> = ref(document.body.clientWidth ?? 0);
const mobileModeWidthTrigger: number = 750;
window.addEventListener("resize",()=>{
    clientWidth.value = document.body.clientWidth;
    if(clientWidth.value < mobileModeWidthTrigger)
        mobileMode.value = true;
    else
    mobileMode.value = false;
})
export const versionNumber: string = "0.61.32";
export const productionMode: boolean = true;
export const SERVER_URL: string =
//"http://localhost:4896";
"https://gw.arknet.online";

export const throwError = (e: any) => {
    console.log(e)
    //showMascotAlert(e);
}
export const navDrawerDisplay: Ref<boolean> = ref(false);

// Access 
export const accessMap: Ref<any> = ref({})

export const copyString = (str: string, e: MouseEvent) => {
    FromString.copyToClipboard(str);
    if(!e) return;
    if(!e.pageX || !e.pageY) return;
    let x = e.pageX;
    let y = e.pageY;
    let stepper: number = y;
    let opacity: number = 1.0;
    const floatingElement = document.createElement("div")
    floatingElement.textContent = "コピーしました!"
    floatingElement.style.position = "fixed";
    floatingElement.style.background = "#4CAF50";
    floatingElement.style.top = `${y}px`;
    floatingElement.style.left = `${x}px`;
    floatingElement.style.fontWeight = `bold`;
    floatingElement.style.zIndex = `10000`;
    floatingElement.style.color = `white`;
    floatingElement.style.padding = `.25em`;
    floatingElement.style.borderRadius = `.5em`;
    document.body.appendChild(floatingElement);
    const animationFrame = () => {
        stepper--;
        const absValue: number = Math.abs(y-stepper);
        if(opacity > 0) opacity = FromNum.minMaxScale(absValue, 30, 0);
        floatingElement.style.top = `${stepper}px`;
        floatingElement.style.opacity = `${opacity}`;
        if(absValue >= 30){
            document.body.removeChild(floatingElement);
            return;
        }
        window.requestAnimationFrame(animationFrame);    
    }
    window.requestAnimationFrame(animationFrame);
}

export const sanitizeContent = (mailContent: string) => {
    const newDOM = document.createElement("div")
    newDOM.innerHTML = mailContent;
    //Modify all content
    //Remove style
    /*
    const allStyle = newDOM.getElementsByTagName("style")
    for(let i = 0; i<allStyle.length; i++)
        allStyle[i].remove();
    */
    const allMeta = newDOM.getElementsByTagName("meta")
    for(let i = 0; i<allMeta.length; i++)
        allMeta[i].remove();
    const allStyle = newDOM.getElementsByTagName("style")
    for(let i = 0; i<allStyle.length; i++)
        allStyle[i].remove();
    const allImg = newDOM.getElementsByTagName("img")
    for(let i = 0; i<allImg.length; i++)
        allImg[i].style.maxWidth = "100%";
    const allTable = newDOM.getElementsByTagName("table")
    for(let i = 0; i<allTable.length; i++)
        allTable[i].style.maxWidth = "100%";
    return newDOM.innerHTML;
};

/**
 * Return list of dates.
 * @param startDate string 
 * @param endDate string
 * @returns list of strirng date in between
 */
export type GetDaysBetweenDatesCallback = (date: string) => void;
export const getDaysBetweenDates = (startDate: string, endDate: string, func: GetDaysBetweenDatesCallback | null = null): string[] => {
    const start: Dayjs = dayjs(startDate);
    const end: Dayjs = dayjs(endDate);
    let now = start.clone(), dates: string[] = [];
    while(now.isSameOrBefore(end)){
        const date: string = now.format('YYYY-MM-DD')
        if(func) func(date);
        dates.push(date);
        now = now.add(1, 'd');
    }
    return dates;
}

export type Day = 
1 | 2 | 3 | 4 | 5 | 6 |
"1" | "2" | "3" | "4" | "5" | "6"
/**
 * 0:Sunday -> 6:Saturday
 * Reutrn japanese days from number|string
 * @param day Day 
 * @returns 
 */
export const getDay = (day: Day) => {
    const map = {
        "0":"日",
        "1":"月",
        "2":"火",
        "3":"水",
        "4":"木",
        "5":"金",
        "6":"土",
    }
    return map[day]
}