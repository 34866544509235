import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { userProfile, verifyUser, getAccessMap } from '@/services/auth';
import { checkAccessibility } from "@/services/auth";


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/dash/',
        name: 'ホーム',
        beforeEnter: async (to, from, next) => {
            try {
                if (!userProfile.value.jwt) return next('/');
                const response = await verifyUser(38560923);
                if(!response) next('/');
                await getAccessMap();
                next();
            } catch (err) {
                next('/');
            }
        },
        component: () => import("@/views/Dashboard.vue"),
        children: [
            {
                path: '',
                name: "ホーム",
                beforeEnter: async (to, from, next) => {
                    if(checkAccessibility(to.name as string)) next()
                },
                component: () => import("@/views/Main.vue")
            },
           
            /*
            //Schedule//
           {
                path: 'userlist',
                name: "ユーザー管理",
                beforeEnter: async (to, from, next) => {
                    if(checkAccessibility(to.name as string)) next()
                },
                component: () => import("@/components/User/UserList.vue")
            },
            //KIJI
            {
                path: 'uk/:id',
                name: "記事閲覧",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/Kiji/UniqueKiji.vue")
            },
            {
                path: 'postManager',
                name: "掲示板管理",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/Kiji/PostManagerPanel.vue"),
                children: [
                    {
                        path: 'editKiji/:id',
                        name: "記事エディタ",
                        component: () => import("@/components/Kiji/KijiEditor.vue")
                    },
                    {
                        path: 'category',
                        name: "記事カテゴリー",
                        component: () => import("@/components/Kiji/CategoryPanel.vue")
                    },
                    {
                        path: 'kijiList',
                        name: "記事一覧",
                        component: () => import("@/components/Kiji/KijiList.vue")
                    },
                ]
            },
            {
                path: 'mail',
                name: "メール",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/EmailReborn/EmailRebornPanel.vue")
            },
            {
                path: 'newMail',
                name: "新規メール",
                props: true,
                component: () => import("@/components/EmailReborn/NewEmail.vue"),
            },
            {
                path: 'userRoles',
                name: "権限管理",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/UserManagement/UserRoleManagement/UserRolesPanel.vue")
            },
            {
                path: 'userGroup',
                name: "ユーザーグループ管理",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/UserManagement/UserGroupManagement/UserGroupPanel.vue")
            },
            {
                path: 'settings',
                name: "設定",
                beforeEnter: async (to, from, next) => {
                    await checkAccessibility(to, next)
                },
                component: () => import("@/components/Settings/SettingsPanel.vue")
            },
            */
            {
                path: '/:pathMatch(.*)*',
                name: 'not-found',
                meta: { title: 'ページは見つかりませんでした' },
                redirect: "/dash/"
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: { title: 'ページは見つかりませんでした' },
        redirect: "/"
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
