import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  ref: "mascotAlertContainer",
  class: "mascotAlertContainer"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_Transition, { name: "bounce" }, {
      default: _withCtx(() => [
        (_ctx.alertCount > 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "mascotAlert",
              width: "50",
              src: require('@/assets/images/monster.svg')
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}