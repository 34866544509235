<template>
    <div>
        <div
            class="updatePanel"
            v-if="updateExists"
        >
            <div class="grid align-center gap-1">
                <img width="50" class="monster" :src="require('@/assets/images/monster.svg')">
                <h4>新バージョンが来たあ!!!</h4>
                <v-spacer />
                <v-btn class="ml-3" @click="refreshApp" color="success"> (┛`д´)┛更新! </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        //PWA related
        refreshing: false,
        registration: null,
        updateExists: false,
        elipsed: 0
    }),
    created() {
        document.addEventListener("swUpdated", this.updateAvailable, {
            once: true,
        });
        navigator.serviceWorker.addEventListener("controllerchange", () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
        });
    },
    methods: {
        updateAvailable(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp() {
            this.updateExists = false;
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return;
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
        },
        //
    },
};
</script>

<style scoped>
.monster{
    position: absolute;
    left: 0;
    top: -40px;
    transform-origin: bottom;
    animation: jump 1s infinite;
}
.updatePanel{
    position: fixed;
    right: 0;
    bottom: 0;
    background: rgb(55, 55, 55);
    padding: .5em;
    border-radius: .5em;
    
}
@keyframes jump {
    0%{
        top: -40px;
        transform: scaleX(1) scaleY(1);
    }
    35%{
        top: -40px;
        transform: scaleX(1.1) scaleY(.9);
    }
    65%{
        top: -80px;
        transform: scaleX(0.9) scaleY(1.1);
    }
    75%{
        top: -80px;
        transform: scaleX(0.9) scaleY(1.1);
    }
    100%{
        top: -40px;
        transform: scaleX(1) scaleY(1);
    }
}
</style>