import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_PWAUpdate = _resolveComponent("PWAUpdate")!
  const _component_ConfirmationPanel = _resolveComponent("ConfirmationPanel")!
  const _component_MascotAlert = _resolveComponent("MascotAlert")!
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "cusFont" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_PWAUpdate),
      _createVNode(_component_ConfirmationPanel),
      _createVNode(_component_MascotAlert),
      (_ctx.mascotLoadingDisplay)
        ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}