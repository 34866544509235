
import { defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
    setup() {

        const triggerAnimation = (mascotId: string, wordId: string) => {
            const _m = document.getElementById(mascotId);
            const _w = document.getElementById(wordId);
            if(!_m || !_w) return;
            _m.classList.toggle("mascotJump", true);
            setTimeout(()=>{
                _w.classList.toggle("jelloHorizontal", true);
            }, 300)
            setTimeout(()=>{
                _m.classList.toggle("mascotJump", false);
                _w.classList.toggle("jelloHorizontal", false);
            }, 600)
        }

        let animationInterval: any = null;

        onMounted(()=>{
            let mascotCounter: number = 1;
            let wordCounter: number = 1;
            animationInterval = setInterval(()=>{
                triggerAnimation(`m${mascotCounter}`, `w${wordCounter}`);
                mascotCounter++;
                wordCounter++
                if(mascotCounter > 5) mascotCounter = 1;
                if(wordCounter > 5) wordCounter = 1;
            }, 600)
        })

        onUnmounted(()=>{
            if(animationInterval)
                clearInterval(animationInterval);
        })
    },
});
