import { ref, Ref, watch } from "vue";

export interface ConfirmationPanelOptions{
    display: boolean,
    title: string
    msg: string,
    confirmOnly: boolean, // This shows only 1 button.
    userConfirmation: number, // 0 = Pristine, 1 = ok, 2 = cancel
}
export const confirmationPanelOptions: Ref<ConfirmationPanelOptions> = ref({
    display: false,
    title: "",
    msg: "",
    confirmOnly: false,
    userConfirmation: 0,
});
export const promptConfirmation = async(title: string, msg: string, confirmOnly: boolean = false): Promise<boolean> => {
    confirmationPanelOptions.value.display = true;
    confirmationPanelOptions.value.title = title;
    confirmationPanelOptions.value.msg = msg;
    confirmationPanelOptions.value.confirmOnly = confirmOnly;
    confirmationPanelOptions.value.userConfirmation = 0;
    return new Promise((resolve)=>{
        const stopper = watch(
            () => confirmationPanelOptions.value.userConfirmation,
            (c) => {
                confirmationPanelOptions.value.display = false;
                if(c === 1) resolve(true);
                if(c === 2) resolve(false);
                stopper();
            }
        )
    })
} 

