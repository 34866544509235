
import { defineComponent, onMounted } from "vue";
import { autoLogin } from "@/services/auth";
import ConfirmationPanel from "@/components/GeneralComponents/ConfirmationPanel/ConfirmationPanel.vue";
import MascotAlert from "@/components/Mascot/MascotAlert.vue";
import LoadingScreen from "@/components/Mascot/LoadingScreen.vue";
import { mascotLoadingDisplay } from "@/services/mascot";
import PWAUpdate from "@/components/GeneralComponents/PWAUpdate.vue";

//TODO: Add secure validation to file route
export default defineComponent({
    components:{
        MascotAlert,
        ConfirmationPanel,
        LoadingScreen, PWAUpdate
    },
    setup() {
        onMounted(()=>{
            autoLogin();
        })

        return{
            mascotLoadingDisplay
        }
    },
});
