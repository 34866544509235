
import { defineComponent } from "vue";
import { confirmationPanelOptions } from "@/services/confirmation-panel";
 
export default defineComponent({
    setup() {
        return{
            confirmationPanelOptions,
        }
    },
});
