import axios from "axios";
import { userProfile, refreshToken } from "@/services/auth";
import { throwError, SERVER_URL } from "@/services/global";
import { mascotLoadingDisplay, showMascotAlert } from "@/services/mascot";


export const getSecured = async(path: string, loadingScreen: boolean = false, logError: boolean = false, maxTry: number = 10): Promise<any> => {
    try{
        if(loadingScreen) mascotLoadingDisplay.value = true;
        const headers = {
            'Content-Type': 'application/json',
            't':userProfile.value.jwt,
        }
        const response = await axios.get(`${SERVER_URL}${path}`,{
            headers,
            withCredentials:true
        })
        if(!response || !response.data) throw "エラーが起きました[005]";
        if(loadingScreen) mascotLoadingDisplay.value = false;
        return response.data;
    }catch(err: any){ 
        if(loadingScreen) mascotLoadingDisplay.value = false;
        // Auto refresh token
        if(err && err.response && err.response.data && err.response.data.expired){
            if(maxTry === 0) return;
            maxTry--;
            await refreshToken();
            return await getSecured(path, loadingScreen, logError, maxTry);
        }
        if(logError && err && err.response && err.response.data){
            showMascotAlert(err.response.data)
        }
        throwError(err);
    }
}


export const postSecured = async(path: string, data: any, loadingScreen: boolean = false, logError: boolean = false, maxTry: number =10): Promise<any> => {
    try{
        if(loadingScreen) mascotLoadingDisplay.value = true;
        const headers = {
            'Content-Type': 'application/json',
            't':userProfile.value.jwt,
        }
        const response = await axios.post(`${SERVER_URL}${path}`, data, {
            headers,
            withCredentials:true
        })
        if(!response || !response.data) throw "エラーが起きました[0006]";
        if(loadingScreen) mascotLoadingDisplay.value = false;
        return response.data;
    }catch(err: any){
        if(loadingScreen) mascotLoadingDisplay.value = false;
        // Auto refresh token
        if(err && err.response && err.response.data && err.response.data.expired){
            if(maxTry === 0) return;
            maxTry--;
            await refreshToken();
            return await postSecured(path, data, loadingScreen, logError, maxTry);
        }
        if(logError && err && err.response && err.response.data){
            showMascotAlert(err.response.data)
        }
        throwError(err);
    }
}
