import { ref, Ref } from "vue";

export const mascotLoadingDisplay : Ref<boolean> = ref(false);

export const mascotAlertContainer: Ref<HTMLElement | null> = ref(null);

export const alertCount: Ref<number> = ref(0);

export const showMascotAlert = (msg: string, life: number = 3000) => {
    if(!mascotAlertContainer.value) return;
    const _alertEl = document.createElement("div");
    _alertEl.setAttribute("class", "mascotAlertItem");
    _alertEl.innerHTML = msg;
    mascotAlertContainer.value.appendChild(_alertEl);
    alertCount.value ++;
    setTimeout(()=>{
        _alertEl.remove();
        alertCount.value --;
    }, life)
}
