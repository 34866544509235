import { ref, Ref } from "vue";
import axios from "axios";
import router from "@/router/index";
import { throwError, SERVER_URL, accessMap } from "@/services/global";
import { getSecured } from "@/services/network";
import { showMascotAlert } from "@/services/mascot";
import { userEmailMap } from "@/services/email";
import { mascotLoadingDisplay } from "@/services/mascot";


export interface User{
    _id: string,
    name: string,
    email: string
}

export interface UserProfile{
    name: string,
    jwt: string,
    id: string,
    accessibility: any
}
export const userProfile: Ref<UserProfile> = ref({
    name: "",
    jwt:"",
    id:"",
    accessibility:{}
})

export interface UserLoginForm{
    valid: boolean,
    email: string,
    password: string
}

export const clearUserProfile = () => {
    userProfile.value.id = "";
    userProfile.value.jwt = "";
    userProfile.value.name = "";
    userProfile.value.accessibility = {};
}
export const applyUserProfile = (uP: any) => {
    userProfile.value.id = uP.id
    userProfile.value.jwt = uP.j;
    userProfile.value.name = uP.name;
}

export const login = async(userForm: UserLoginForm) => {
    try{
        mascotLoadingDisplay.value = true;
        let response = await axios.post(`${SERVER_URL}/auth/login`, userForm, {
            withCredentials: true,
        });
        if(!response || !response.data) throw "エラーが起きました[001]";
        applyUserProfile(response.data)
        mascotLoadingDisplay.value = false;
        router.replace('/dash');
    }catch(err: any){ 
        mascotLoadingDisplay.value = false;
        if(err && err.response && err.response.data){
            showMascotAlert(err.response.data)
        }
        throwError(err) 
    };
}


export const autoLogin = async(noRoute: boolean = false) => {
    try{
        const newJwt = await axios.get(`${SERVER_URL}/auth/autoLog`,{
            withCredentials:true,
        })
        if(!newJwt || !newJwt.data) throw "エラーが起きました[002]";
        applyUserProfile(newJwt.data)
        if(noRoute) return;
        if(newJwt.data.acc === 38560923) router.replace("/dash")
        else router.replace("/")
    }catch(err){
        //throwError(err);
    }   
}

export const logout = async() => {
    try{
        await getSecured("/auth/logout");
        clearUserProfile()
        window.location.reload();
    }catch(err){ throwError(err)}   
}

export const verifyUser = async(accType: number): Promise<boolean> => {
    try{
        const response = await axios.get(`${SERVER_URL}/auth/verify`, {
            withCredentials:true,
            headers:{
                'Content-Type': 'application/json',
                'j':userProfile.value.jwt,
                't':accType
            }
        })
        if(!response || !response.data) throw "エラーが起きました[003]";
        return response.data 
    }catch(err){
        throwError(err)
        return false;
    } 
}

export const refreshToken = async () => {
    try{
        const response = await axios.post(`${SERVER_URL}/auth/refresh`,
            { j: userProfile.value.jwt },
            { withCredentials:true, }
        )
        if(response && response.data && response.data.j){
            userProfile.value.jwt = response.data.j;
        }
    }catch(err){
        throwError(err)
    }  
}

export const getAccessMap = async () => {
    try{
        const result = await getSecured("/api/getAccessMap")
        if(!result) throw "エラーが起きました[004]";
        if(result.accessMap) accessMap.value = result.accessMap;
        if(result.accessibility && result.accessibility.accessible)
            userProfile.value.accessibility = result.accessibility.accessible;
    }catch(err){throwError(err)}  
}

export const checkAccessibility = (payload: string) => {
    for(let sectionId in accessMap.value){
        if(accessMap.value[sectionId] === payload){
            if(userProfile.value.accessibility[sectionId] === true)
                return true
            else return false
        }
    }
    return false
}

export const userMap: Ref<{[key: string]: User}> = ref({});

export const getUsers = async(): Promise<User[]> => {
    const response = await getSecured('/api/getUserList');
    if(response && response.result) return response.result as User[];
    return [];
}

export const applyUsers = (users: User[]) => {
    for(let i = 0; i < users.length; i++){
        userEmailMap.value[users[i].email] = users[i].name;
        userMap.value[users[i]._id] = {...users[i]};
    }
}
