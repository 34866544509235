
import { defineComponent } from "vue";
import { alertCount, mascotAlertContainer } from "@/services/mascot";
 
export default defineComponent({
    setup() {
        return{
            alertCount, mascotAlertContainer
        }
    },
});
