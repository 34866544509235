import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.confirmationPanelOptions.display,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmationPanelOptions.display) = $event)),
    persistent: "",
    width: "300"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmationPanelOptions.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmationPanelOptions.msg), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "success",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmationPanelOptions.userConfirmation = 1))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("OK")
                ]),
                _: 1
              }),
              (!_ctx.confirmationPanelOptions.confirmOnly)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "error",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmationPanelOptions.userConfirmation = 2))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("キャンセル")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}