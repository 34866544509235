import { ref, Ref, watch, computed} from "vue";
import { userProfile } from "@/services/auth";
import { Generate, Is, Delay } from "cerceis-lib";
import { promptConfirmation } from "./confirmation-panel";
import { getSecured, postSecured } from "@/services/network";
import { throwError, CustomFileObject } from "@/services/global";

/**
 * Map user email with its name.
 * {
 *     abc@something.com: "小林",
 *     bca@soaething.com: "花子",
 * }
 * Mapping could be in anywhere that include fetching data with email and name.
 * Such as: Emails, Addressbook, etc
 */
// =============================EmailMap================================
export const emailReferencePanelDisplay: Ref<boolean> = ref(false);
export interface UserEmailMap{
    [key: string]: string
}
export const userEmailMap: Ref<UserEmailMap> = ref({});
// =================================================================∏
export interface EmailAttachment{
    content: string,
    filename: string,
    type: string,
    disposition: string
}
// ==============================Drafts===================================
export interface EmailDraft{
    id: string,
    valid: boolean,
    minimized: boolean,
    title: string,
    dateCreated: string,
    context: string,
    by: string,
    to: string[],
    cc: string[],
    bcc: string[],
    att: EmailAttachment[]
}
export interface Email{
    _id: string,
    userId: string,
    from: string,
    globalTo: string,
    spamScore: number,
    to: string[],
    cc: string[],
    bcc: string[],
    title: string,
    dateCreated: string,
    read: boolean,
    replied: boolean,
    flag: boolean,
    attachments: boolean,
    attachmentList: EmailAttachment[] | null,
    deleted: boolean,
    content: string,
    headers: string,
    envelope: string,
    tags: string[]
}
export interface EmailForm{
    _id: string,
    valid: boolean,
    title: string,
    context: string,
    to: string[],
    cc: string[],
    bcc: string[],
    att: CustomFileObject[],
    replyParent?: string,
}

export interface EmailAddressbookItem{
    _id?: string,
    name: string,
    email: string,
    companyName: string,
    department: string,
    position: string,
    address: string,
    phone: string,
    memo: string,
    group: string[]
}

export const newEmailDraft = (): void => {
    emailDraftLists.value.push({
        id: Generate.objectId(),
        valid: false,
        minimized: false,
        title: `名称未設定メール`,
        dateCreated: Generate.currentDate(),
        context: "",
        by: userProfile.value.id,
        to: [],
        cc: [],
        bcc: [],
        att: []
    })
}
/**
 * Global email draft list.
 */
export const emailDraftLists: Ref<EmailDraft[]>  = ref([]);
/**
 * Get the object of the single draft by id
 * @param draftId The draft's local id
 * @returns 
 */
export const getEmailDraftById = (draftId: string): EmailDraft | null => {
    if(!draftId || emailDraftLists.value.length === 0) return null;
    const target: EmailDraft | undefined = emailDraftLists.value.find(e => e.id === draftId) 
    return target ? target : null;
}
export const deleteEmailDraftById = async (draftId: string): Promise<void> => {
    const confirmed: boolean = await promptConfirmation("警告", "下書きを削除しますか？");
    if(confirmed){
        const targetIndex: number = emailDraftLists.value.findIndex(e => e.id === draftId);
        if(targetIndex >= 0) emailDraftLists.value.splice(targetIndex, 1);
    }
}
// =================================================================
// ===============================Email===============================

// Define height for virtual/infinite scroller
export const emailInfiniteScrollerHeight: Ref<number> = ref(400);
// Selected Emails
export const selected: Ref<any[]> = ref([]);
export const emails: Ref<Email[]> = ref([]);
export const selectedUniqueEmail: Ref<any> = ref(null);
// Backups, so don't have to refetch.
export const backupEmails: Ref<any[]> = ref([]);
export const backupSentEmails: Ref<any[]> = ref([]);
//0 = 受信, 1 = 送信, 2 = ゴミ箱m 3 = 迷惑メール
export const mailBox: Ref<any> = ref([
    {title:'受信', value: 0}, 
    {title:'送信', value: 1}, 
    {title:'ゴミ箱', value: 2},
    {title:'迷惑メール', value: 3}
]);
export const selectedMailbox: Ref<any> = ref(0);
// Allow Refresh flag, to prevent user kept refreshing the emails
export const isRefreshBtnDisabled: Ref<boolean> = ref(false);
// Initialize Fetch sequence options/ properties
export const skip: Ref<number> = ref(0);
export const limit: Ref<number> = ref(100);
export const status: Ref<string> = ref("初期化中");

watch(
    // Refresh everytime the user changes mailbox
    // otherwise the selected emails will carry over
    () => selectedMailbox.value,
    (c, previous) => {
        selected.value = [];
        if(previous === 1){
            emails.value = backupEmails.value;
        }
        if(selectedMailbox.value === 1){
            backupEmails.value = [...emails.value];
            selected.value = [];
            emails.value = [];
            skip.value = 0;
            initSentEmailFetchSequence();
        }
    }
)

// Fetch all remaining mails
export const fetchAllRemainingEmails: Ref<boolean> = ref(false);
export const initEmailFetchSequence = async () => {
    try{
        isRefreshBtnDisabled.value = true;
        status.value = "メールを読み込み中";
        const response: any = await getSecured(`/mailReborn/getEmails?l=${limit.value}&s=${skip.value}`);
        if (response && response.result && selectedMailbox.value !== 1){
            // Push into emails and map UserEmailMap
            for(let i = 0; i<response.result.length; i++){
                emails.value.push(response.result[i]);    
                if(response.result[i].from){
                    const senderInfo = parseEmailSenderString(response.result[i].from);
                    if(senderInfo && !userEmailMap.value[senderInfo.email])
                        userEmailMap.value[senderInfo.email] = senderInfo.name;
                }                    
            }
            const batchSize = response.result.length;
            if(batchSize === limit.value){
                skip.value += batchSize;
                if(fetchAllRemainingEmails.value !== false)
                    await initEmailFetchSequence();
                status.value = "完了";
                isRefreshBtnDisabled.value = false;
                initTags();
            }else{
                fetchAllRemainingEmails.value = true;
                status.value = "完了";
                isRefreshBtnDisabled.value = false;
                initTags();
            }
        }
    }catch(err: any){
        throwError(err);
    }    
}

export const initSentEmailFetchSequence = async () => {
    try{
        isRefreshBtnDisabled.value = true;
        status.value = "メールを読み込み中";
        const response: any = await getSecured(`/mailReborn/getSentEmails?l=${limit.value}&s=${skip.value}`);
        if (response && response.result && selectedMailbox.value === 1){
            emails.value = [...emails.value, ...response.result];
            const batchSize = response.result.length;
            if(batchSize === limit.value){
                skip.value += batchSize;
                initSentEmailFetchSequence();
            }else {
                skip.value += batchSize;
                status.value = "完了";
                isRefreshBtnDisabled.value= false;
            }
        }
    }catch(err:any){
        throwError(err);
    }
};

// Fetch single email content
export const getUniqueEmail = async (url: string) => {
    try{
        const response: any = await getSecured(url, true);
        if(response && response.result) return response.result;
    }catch(err:any){
        throwError(err);
    }
}

// Fetch newest @num number of emails
export const fetchNewestEmail = async (num: number) => {
    try{
        if(!num) return;
        isRefreshBtnDisabled.value = true;
        status.value = "メールを読み込み中";
        const response: any = await getSecured(`/mailReborn/getEmails?l=${num}&s=0`);
        if (response && response.result && selectedMailbox.value !== 1){
            // Push into emails and map UserEmailMap
            for(let i = 0; i<response.result.length; i++){
                emails.value.unshift(response.result[i]);    
                if(response.result[i].from){
                    const senderInfo = parseEmailSenderString(response.result[i].from);
                    if(senderInfo)
                        userEmailMap.value[senderInfo.email] = senderInfo.name;
                }                    
            }
            status.value = `最終更新: ${Generate.currentTime()}`;
            isRefreshBtnDisabled.value = false;
        }
    }catch(err: any){
        throwError(err);
    }    
}
// Check if there's new email for the user.
export const checkNewEmails = async (): Promise<{result: boolean, count: number}> => {
    try{
        status.value = "新規メール確認中"
        const response: any = await getSecured('/mailReborn/checkNewMails');
        //status.value = `最終更新: ${Generate.currentTime()}`;
        if(response && response.result && response.count) return response;
        return {result: false, count: 0};
    }catch(err:any){
        throwError(err);
        status.value = "新規メール確認失敗しました"
        return {result: false, count: 0};
    }
}
export const initializeEmailCheckInterval = async () => {
    const hasNewEmails: any = await checkNewEmails();
    if(hasNewEmails && hasNewEmails.count > 0){
        fetchNewestEmail(hasNewEmails.count);
    }
    await Delay(1000 * 60 * 5); // 5 minutes
    initializeEmailCheckInterval();
}

export const triggerFlag = async(e: Event, emailData: Email) => {
    e.stopPropagation()
    const newFlag = await postSecured('/mailReborn/toggleFlag', {
        userId: userProfile.value.id, 
        emailId: emailData._id,
        flag:emailData.flag === undefined ? false : emailData.flag
    })
    if(newFlag && newFlag.result !== undefined){
        emailData.flag = newFlag.result
    }
}
// TODO: delete sent email lol
export const toggleDelete = async(type: string = "1", single: any = null) => {
    if(!single && selected.value.length <= 0) return
    if(single && !Is.string(single)) return
    const response = await postSecured(`/mailReborn/toggleDelete?t=${type}`,
        {
            mode: 0,
            selectedEmails: single ? [single] : selected.value
        }
    )
    if(response && response.result){
        // Done, toggle all selected local record to deleted
        if(single){
            const target = emails.value.find(e => e._id === single);
            if(target) target.deleted = type === "1" ? true : false;
        }else{
            selected.value.forEach(id => {
                const target = emails.value.find(e => e._id === id);
                if(target) target.deleted = type === "1" ? true : false;
            });
        }
        selected.value = [];
    }   
}

export const deleteEmail = async(id: string = "") => {
    if(!id && selected.value.length <= 0) return;
    if(id && !Is.string(id)) return;
    const accepted = await promptConfirmation(
        "削除しますか？",
        "選んだメールをを削除しますか？この操作が元に戻せないので注意してください。"
    );
    if(accepted) {
        const response = await postSecured("/mailReborn/deleteEmail",{
            mode: selectedMailbox.value, // Mode 1 === delete sent email, else emails
            selectedEmails: id ? [id] : selected.value,
        })
        if(response && response.result){
            // Done, delete the mail locally
            if(id){
                const targetIndex = emails.value.findIndex(e => e._id === id);
                if(targetIndex > -1) emails.value.splice(targetIndex, 1);
            }else{
                selected.value.forEach(id => {
                    const targetIndex = emails.value.findIndex(e => e._id === id);
                    if(targetIndex > -1) emails.value.splice(targetIndex, 1);
                });
            }
            selected.value = [];
        }
    }
}    

export interface RecipientInfo {
    name: string,
    email: string
}
export const parseEmailSenderString = (fromString: string): RecipientInfo => {
    const removeUnwantedContainer = (str: any) => {
        if(!str) return str;
        if(str[0] === '"' || str[0] === "'" || str[0] === "<") 
            str = str.slice(1, str.length);
        if(str.at(-1) === '"' || str.at(-1) === "'" || str.at(-1) === ">") 
            str = str.slice(0, -1);
        return str.trim();
    }
    if(!fromString) return {name: fromString, email: fromString};
    const splitStr: string[] = fromString.split("<");
    if(splitStr.length < 2){
        let unknownString: string = splitStr[0];
        if(!unknownString) unknownString = splitStr[1];
        unknownString = removeUnwantedContainer(unknownString);
        return { name: unknownString, email: unknownString };
    }
    let name: string = removeUnwantedContainer(splitStr[0].trim());
    let email: string = removeUnwantedContainer(splitStr[1].trim());
    if(!name && email) return { name: email, email };
    if(name && !email) return { name, email: name };
    return { name, email }
}
export const parseEmailGlobalTo = (str: string): RecipientInfo[] => {
    if(!str) return [];
    let arr: string[] = str.split(",");
    if(arr.length === 0) return [{name:str, email:str}];
    const fixedArr: RecipientInfo[] = arr.map((s: string) => {
        s.trim();
        const info = parseEmailSenderString(s);
        if(!info) return {name:s, email:s} as RecipientInfo;
        if(info.name && info.email) return info as RecipientInfo;
        if(!info.name && info.email) return {name:s, email:info.email} as RecipientInfo;
        if(info.name && !info.email) return {name:info.name, email:s} as RecipientInfo;
        return {name:s, email:s} as RecipientInfo
    });
    return fixedArr;
}


export const signatureDialog: Ref<boolean> = ref(false);
export const getEmailSignature = async(): Promise<string> => {
    try{
        const response = await getSecured(`/mailReborn/getEmailSignature`);
        if(response && response.result) return response.result.signature;
        return "";
    }catch(err){
        throwError(err)
        return "";
    }
}
export const updateEmailSignature = async(newSignature: string): Promise<string> => {
    try{
        const response = await postSecured(`/mailReborn/updateEmailSignature`, {signature: newSignature}, true);
        if(response && response.result) return response.result.signature;   
        return "";
    }catch(err){
        throwError(err)
        return "";
    }
}

export const tagsToFilter: Ref<any[]> = ref([]);
export const newTagName: Ref<string> = ref("");
export const tagSet: Ref<Set<string>> = ref(new Set());
export const tags = computed(() => [...tagSet.value]);
//export const tagInitiated: Ref<boolean> = ref(false);
export const initTags = () => {
    for(let i = 0; i<emails.value.length ; i++){
        if(emails.value[i].tags.length === 0) continue;
        emails.value[i].tags.forEach(t => tagSet.value.add(t));
    }
}

// Email addressbook
export const addressbook: Ref<EmailAddressbookItem[]> = ref([]);
export const getAddressBook = async() => {
    try{
        const response = await getSecured(`/api/getAddressBook`, true);
        if(response && response.result) return response.result;
        return null
    }catch(err){
        throwError(err)
        return null;
    }
}
export const updateAddressBook = async(item:EmailAddressbookItem) => {
    try{
        const formData: any = item;
        formData.userId = userProfile.value.id;
        const response = await postSecured(`/api/updateAddressBook`, formData ,true);
        if(response && response.result) return response.result;
        return null
    }catch(err){
        throwError(err)
        return null;
    }
}
export const deleteAddressBook = async(tids: string[]) => {
    try{
        const response = await postSecured(`/api/updateAddressBook`, {
            uid: userProfile.value.id,
            tids: tids,
        } ,true);
        if(response && response.result) return response.result;
        return null
    }catch(err){
        throwError(err)
        return null;
    }
}



// Drafts
interface DraftListItem{
    _id: string,
    name: string
}
export const draftList: Ref<DraftListItem[]> = ref([]);
export const saveDraft = async(email: EmailForm): Promise<boolean> => {
    try{
        const response = await postSecured(`/mailReborn/saveDraft`, email);
        if(response && response.result) return response.result;
        return false
    }catch(err){
        throwError(err)
        return false;
    }
}
export const destoryDraft = async(id: string): Promise<boolean> => {
    try{
        const response = await postSecured(`/mailReborn/destoryDraft`, {id}, true);
        if(response && response.result) return response.result;
        return false
    }catch(err){
        throwError(err)
        return false;
    }
}
export const getDrafts = async(): Promise<DraftListItem[]> => {
    try{
        const response = await getSecured(`/mailReborn/getDrafts`);
        if(response && response.result) return response.result;
        return []
    }catch(err){
        throwError(err)
        return [];
    }
}
export const loadDraft = async(id: string): Promise<EmailForm | null> => {
    try{
        if(!id) return null;
        const response = await getSecured(`/mailReborn/loadDraft?id=${id}`);
        if(response && response.result) return response.result;
        return null
    }catch(err){
        throwError(err)
        return null;
    }
}